/************ Custom Font ************/
@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Book.ttf');
  font-weight: 400;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #F5F5F5 !important;
  color: #08386f !important;
  font-family: 'Centra', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

@media (min-width:1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}

p.success {
  color: green;
}

p.danger {
  color: red;
}

/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  /* background-color: #1F4E79; */
  transition: 0.32s ease-in-out;
}

nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #08386f;
  min-height: 80px;
}

nav.navbar a.navbar-brand {
  width: 15%;
  min-width: 80px;
  filter: invert(100%) sepia(1%) saturate(414%) hue-rotate(46deg) brightness(116%) contrast(92%);
  /* filter: invert(28%) sepia(11%) saturate(3002%) hue-rotate(168deg) brightness(91%) contrast(93%); */
}

nav.navbar.scrolled a.navbar-brand {
  width: 15%;
  min-width: 80px;
  filter: invert(100%) sepia(1%) saturate(414%) hue-rotate(46deg) brightness(116%) contrast(92%);
}

/* Social Icons */
.social-icon {
  display: inline-block;
  margin-left: 14px;
}

.social-icon a {
  width: 42px;
  height: 42px;
  background: #F5F5F5;
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #08386f;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}

.social-icon a:hover::before {
  transform: scale(1.2);
}

.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

/************ Analyze Css ************/
.analyze {
  padding: 30px 0 10px 0;
  position: relative;
  height: auto;
  z-index: 99;
}

.analyze-bx {
  position: relative;
  bottom: 20vh;
  z-index: 1;
  background: #fff;
  -webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  border: 1px solid #6868687d;
  border-radius: 5px;
  /* padding: 60px 50px; */
  margin-top: 10px;
  text-align: left;
  width: 900px !important;
  height: auto;
  max-width: 1100px !important;
}

.analyze h2 {
  font-size: 40px;
  font-weight: 700;
}

.analyze p {
  color: #686868;
  font-size: 20px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0 35px 0;
}

.analyze .sub-sec .txt-bx {
  margin-top: 0;
  padding: auto;
  width: 100%;
}

.txt-bx .top-bx{
  background-color: #FFFFFF;
  border: 1px solid #6868687d;
  border-radius: 5px 5px 0 0;
  height: 5vh;
  margin-top: 0;
  padding: auto;
  width: 100%;
}

.txt-bx .code-input-bx{
  background-color: #f5f5f5;
  margin-top: 0;
  padding: auto;
  width: 100%;
  /* overflow: scroll; */
}

.txt-bx .btm-line{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #FFFFFF;
  border: 1px solid #6868687d;
  border-radius: 0 0 5px 5px;
  height: 5vh;
  margin-top: 0;
  padding: auto;
  width: 100%;
}

.sub-sec .txt-bx .text-area {
  width: 100%;
  height: 100%;
  min-height: 300px;
  border-top: 2px solid #686868;
  border-bottom: 2px solid #686868;
  background-color: #EBEBEB;
  /* border: 2px solid #686868; */
  /* border-radius: 10px; */
  padding-left: 5px;
  padding-top: 5px; 
}

.analyze .loading-section{
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 30px 20px;
}

.analyze .prog-bar-cont {
  width: 80%;
  height: 30px;
}

.analyze .prog-bar {
  width: 80%;
  height: 30px;
}

.bg-prog {
  background-color: #08386f !important;
}

.analyze .check-btn {
  /* margin: 30px 0; */
  padding: 15px 25px;
  /* margin-top: 20px; */
  border: unset;
  border-radius: 15px;
  color: #08386f;
  z-index: 1;
  background: #e8e8e8;
  position: relative;
  /* left: 700px; */
  font-weight: 1000;
  font-size: 17px;
  -webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  transition: all 250ms;
  overflow: hidden;
}

.analyze .check-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  border-radius: 15px;
  background-color: #08386f;
  z-index: -1;
  -webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  transition: all 250ms
}

.analyze .check-btn:hover {
  color: #e8e8e8;
}

.analyze .check-btn:hover::before {
  width: 100%;
}

.analyze .load-circle {
  margin-right: 20px;
}

/* Svg Styles */
.analyze .input-svgs {
  width: 30px;
  margin: 0 10px 5px 5px;
  filter: invert(21%) sepia(74%) saturate(823%) hue-rotate(177deg) brightness(96%) contrast(89%);
}

/************ Reports Css ************/
.reports {
  padding: 30px 0 50px 0;
  position: relative;
}

.reports-bx {
  background: #fff;
  border-radius: 10px;
  padding: 60px 50px;
  margin-top: 0px;
  text-align: left;
  max-width: 2000px;
}

.reports h2 {
  font-size: 40px;
  font-weight: 700;
  padding: 0 0 10px 0;
}

.reports h3 {
  font-size: 30px;
  font-weight: 500;
  padding: 0 0 20px 0;
  margin: 20px 0;
}

.reports p {
  color: #686868;
  font-size: 20px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 12px 0 12px 0;
}

.reports .result-txt {
  margin-left: 30px;
  margin-right: 200px;
}

.reports .detail-subhead {
  font-size: 20px !important;
  font-weight: 600;
}

.reports .list-item {
  border: none;
}

.reports .report-svgs {
  width: 35px;
  margin-right: 5px;
}

.reports .sub-sec {
  display: flex;
  padding: 0 50px 0 10px;
}

.reports .sub-sec .code-area {
  width: 100%;
  height: 100%;
  min-height: 300px;
  border: 2px solid #686868;
  border-radius: 10px;
  padding-left: 5px;
  padding-top: 5px; 
}

.reports .sub-sec p {
  width: 80%;
}

.reports-bx .code-section {
  margin-top: 0;
  border-radius: 10px;
  padding: auto;
  width: 100%;
}

.code-section .top-bx{
  display: flex;
  justify-content: right;
  background-color: #FFFFFF;
  border: 1px solid #6868687d;
  border-radius: 5px 5px 0 0;
  height: 5vh;
  margin-top: 0;
  padding: auto;
  padding-right: 5px;
  width: 100%;
}

.top-bx .code-bx-svgs{
  height: 25px; 
}

.code-section .code-input-bx{
  background-color: #f5f5f5;
  border-left: 1px solid #6868687d;
  border-right: 1px solid #6868687d;
  height: auto;
  min-height: 10vh;
  margin-top: 0;
  padding: auto;

  width: 100%;
}

.code-section .btm-line{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #FFFFFF;
  border: 1px solid #6868687d;
  border-radius: 0 0 5px 5px;
  height: auto;
  margin-top: 0;
  padding: auto;
  width: 100%;
}


/************ About Css ************/
.about {
  padding: 30px 0 50px 0;
  position: relative;
}

.about-bx {
  background: #fff;
  border-radius: 10px;
  padding: 60px 50px;
  margin-top: 0px;
  margin-bottom: 40px;
  text-align: left;
  max-width: 2000px;
}

.about-bx .card-bx{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.about-bx .abt-card{
  border: none;
  align-items: center;
  text-align: center;
}

.abt-card .card-title{
  color: #08386f;
}

.about .container h2 {
  font-size: 40px;
  font-weight: 700;
  padding: 0 0 10px 0;
}

.about .abt-heading {
  text-align: center;
  margin: 0 0 20px 20px;
}

.about .abt-subhead {
  font-size: 17px !important;
  font-weight: 500;
  color: #08386f;

}

.about h3 {
  font-size: 35px;
  font-weight: 500;
  padding: 0 0 10px 0;
  margin-bottom: 20px;
}

.about p {
  color: #686868;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 10px 0 10px 0;
}

.about .sub-sec {
  display: flex;
}

.about .list-item {
  border: none;
}

/* Svg Styles */
.about .abt-svgs {
  width: 30px;
  margin-right: 5px;
  filter: invert(21%) sepia(74%) saturate(823%) hue-rotate(177deg) brightness(96%) contrast(89%);
}

/* PNGS Style */
.about .abt-png{
  width: 70px;
  margin-bottom: 50px;
}

/************ Footer Css ************/
.footer {
  padding: 0 0 10px 0;
  /* background-image: url('./assets/img/footer-bg1.png'); */
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background: #08386f;
}

.footer .col-1,
.footer .col-2 {
  margin-top: 30px;
}

.footer .ftr-logo {
  width: 30%;
  min-width: 80px;
  filter: invert(100%) sepia(1%) saturate(414%) hue-rotate(46deg) brightness(116%) contrast(92%);
}

.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #F5F5F5;
  letter-spacing: 0.5px;
  margin-top: 20px;
}

/* Responsive layout 1 */
/* @media only screen and (max-width: 1024px) {
  .about .sub-sec {
    display: block;
  }

  .analyze .check-bar {
    display: block;
    width: 100%;
    margin-top: 20px;
  }

  .analyze .prog-bar {
    width: 100%;
    height: 20px;
    margin-bottom: 20px;
  }

  .reports .sub-sec {
    display: block;
  }
} */

/************ Responsive Css ************/

/* Tablets */
@media (max-width: 1024px) {
  nav.navbar a.navbar-brand {
    width: 20%;
  }

  .social-icon a {
    width: 36px;
    height: 36px;
  }

  .analyze-bx {
    width: 90% !important;
  }

  .analyze h2 {
    font-size: 32px;
  }

  .analyze p {
    font-size: 18px;
    margin: 12px 0 25px 0;
  }

  .analyze .check-btn {
    font-size: 15px;
    padding: 12px 20px;
  }

  .txt-bx .top-bx{
    font-size: 15px;
  }
  
  
  .txt-bx .btm-line{
    height: auto;
    font-size: 15px;
  }


  /* Svg Styles */
  .analyze .input-svgs {
    width: 27px;
  }

  .reports-bx {
    padding: 40px 30px;
  }

  .reports .result-bx {
    width: 100%;
  }

  .reports h2 {
    font-size: 32px;
  }

  .reports h3 {
    font-size: 24px;
  }

  .reports p {
    font-size: 18px;
    margin: 5px 0;
  }

  .reports .result-txt {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .about-bx {
    padding: 40px 30px;
  }

  .about .container h2 {
    font-size: 32px;
  }

  .about h3 {
    font-size: 28px;
  }

  .about p {
    font-size: 16px;
  }
}

/* Phones */
@media (max-width: 768px) {
  nav.navbar a.navbar-brand {
    width: 30%;
  }

  nav.navbar.scrolled a.navbar-brand {
    width: 30%;
  }

  .social-icon a {
    width: 32px;
    height: 32px;
  }

  .analyze-bx {
    width: 95% !important;
  }

  .analyze h2 {
    font-size: 28px;
  }

  .analyze p {
    font-size: 16px;
    margin: 10px 0 20px 0;
  }

  .analyze .check-btn {
    font-size: 16px !important;
    padding: 10px 15px;
  }


  .reports-bx {
    padding: 30px 0px;
  }

  .reports .sub-sec1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .reports .result-txt {
    display: none;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .reports .sub-sec {
    display: flex !important;
    justify-content: left !important;
  }

  .reports .sub-sec .meter {
    position: relative;
    left: 70px;
    width: 70%;
    text-align: center !important;
  }


  /* .reports .sub-sec1 .meter {
    width: 70% !important;
    
    /* Ensure the meter takes full width */
  /* }  */

  .reports .sub-sec p {
    font-size: 16px;
    width: 100% !important;
    padding: 0 0 0 0;
  }

  .reports .sub-sec .img {
    padding: 0px;
    margin: 0px;
  }

  .reports .report-svgs {
    width: 25px;
    margin-right: 0;
  }

  .reports .detail-subhead {
    font-size: 22px !important;
  }

  .reports h2 {
    font-size: 28px;
    margin-left: 20px;
  }

  .reports h3 {
    font-size: 22px;
    margin-left: 20px;
  }

  .reports p {
    font-size: 16px !important;
    margin: 8px 0;
  }

  .about-bx {
    padding: 30px 20px;
  }

  .about .container h2 {
    font-size: 28px;
  }

  .about .sub-sec{
    display: block;
  }

  .about .card-bx {
    display: block;
  }
  .about .abt-card{
    justify-self: center;
  }

  .about h3 {
    font-size: 24px;
  }

  .about p {
    font-size: 16px !important;
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    padding: 20px 15px;
  }

  .footer .ftr-logo {
    width: 100%;
    margin-bottom: 10px;
    align-self: flex-start;
  }

  .footer .col-1 {
    width: 30%;
  }

  .footer .col-2 {
    width: 70%;
  }

  .footer .social-icon {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 10px;
  }

  .footer .social-icon a {
    width: 32px;
    height: 32px;
    margin: 0 5px;
  }

  .footer p {
    font-size: 12px;
    width: 100%;
    text-align: right;
    margin-top: 10px;
  }
}

/* Small Phones */
@media (max-width: 480px) {
  nav.navbar a.navbar-brand {
    width: 40%;
  }

  .social-icon a {
    width: 28px;
    height: 28px;
  }

  .analyze-bx {
    width: 95% !important;
  }

  .analyze-bx {
    padding: 20px 15px;
  }

  .analyze h2 {
    font-size: 24px;
  }

  .analyze p {
    font-size: 14px;
    margin: 8px 0 15px 0;
  }

  .analyze .check-btn {
    font-size: 12px;
    padding: 8px 12px;
  }

  .reports-bx {
    padding: 20px 15px;
  }

  .reports h2 {
    font-size: 24px;
  }

  .reports h3 {
    font-size: 20px;
  }

  .reports p {
    font-size: 14px;
    margin: 6px 0;
  }

  .about-bx {
    padding: 20px 15px;
  }

  .about .container h2 {
    font-size: 24px;
  }

  .about h3 {
    font-size: 20px;
  }

  .about p {
    font-size: 12px;
  }

  .footer .ftr-logo {
    width: 60%;
  }

  .footer p {
    font-size: 10px;
  }
}