.banner {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 65vh;
    background: linear-gradient(to bottom, #08386f 0%, #08386f 30%);
    /* background: linear-gradient(to bottom, #1F4E79 0%, #1F4E79 30%); */
    overflow: hidden;
  }
  
  .banner svg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
  }
  
  .banner-content {
    max-width: 900px;
    position: relative;
    z-index: 10;
    color: #f5f5f5;
    text-align: center;
    padding-top: 10vh;
  }

  .banner-content .up-header{
    background: linear-gradient( white, #007eb4);
                -webkit-background-clip: text;
                background-clip: text;
                color: transparent;
  }
  
  h1 {
    font-size: 3rem;
  }
  
  p {
    font-size: 1.5rem;
  }
  

/* Tablets */
@media (max-width: 1024px) {
  .banner {
    width: 100%;
    height: 50vh;
  }
  .banner-content {
    max-width: 700px;
  }
  h1 {
    font-size: 2.5rem;
  }
  
  p {
    font-size: 1.3rem;
  }
}

/* Phones */
@media (max-width: 768px) {
  .banner {
    width: 100%;
    height: 52vh;
  }
  .banner-content {
    max-width: 400px;
  }
  h1 {
    font-size: 2.3rem;
  }
  
  p {
    font-size: 1.1rem;
  }
}

/* Small Phones */
@media (max-width: 480px) {}
